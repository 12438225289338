import React from 'react'
import getYouTubeId from 'get-youtube-id'
import YouTube from 'react-youtube'
import { TwitterTweetEmbed } from 'react-twitter-embed'
import InstagramEmbed from 'react-instagram-embed'
import Figure from './Figure'
import Container from '@material-ui/core/Container'
const serializers = {
  types: {
    authorReference: ({node}) => <span>{node.author.name}</span>,
    mainImage: Figure,
    youtube: ({node}) => {
      const opts = {
        width: '100%',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 0,
          modestbranding: 1,
          rel: 0,
          playsinline: 1
        },
      };
      const { url } = node
      const id = getYouTubeId(url)
      return (
        <Container maxWidth='sm'>
        <YouTube videoId={id} opts={opts} />
        </Container>
        )
    },
    twitter: ({node}) => {
      const { id } = node
      return (
        <Container maxWidth='sm'>
        <TwitterTweetEmbed tweetId={id} options={{ conversation: "none" }} />
        </Container>
        )
    },
    instagram: ({node}) => {
      const { id } = node
      return (
        <Container maxWidth='sm'>
        <InstagramEmbed url={`https://www.instagram.com/p/${id}/`} maxWidth='100%'/>
        </Container>
        )
    },

    

  }
}

export default serializers
